import React from 'react';
import classes from './Tag.module.scss';

import Tooltip from '../Tooltip/Tooltip';
import tagConfigs from './tagConfigs';
import Txt from '../Txt/Txt';

export interface TagProps {
  tag: string;
  type: 'survey' | 'cashback' | 'deal';
  className?: string;
}

const Tag: React.FC<TagProps> = function Tag({ tag, type, ...rest }) {
  const config = tagConfigs[type][tag];
  return config ? (
    <Tooltip
      tipClassName={classes.tip}
      tip={
        <div className={classes.tagContent}>
          <Txt component="h4">{config.title}</Txt>
          <Txt component="p">{config.description}</Txt>
        </div>
      }
      {...rest}
    >
      <config.Icon className={classes.icon} />
    </Tooltip>
  ) : null;
};

export default Tag;
