import clsx from 'clsx';
import React from 'react';
import classes from './SignUpSurveyItem.module.scss';

import Oinq from 'components/assets/oinq/oinq_success.svg';
import Txt from 'components/atoms/Txt/Txt';
import Button from '../../atoms/Button/Button';
import Card from '../../atoms/Card/Card';
import { SCREENER_SURVEY, SIGN_UP } from '../../pages/pages';
import { useIsLoggedIn } from '../../templates/AuthenticationProvider/AuthenticationProvider';
import { SurveyType } from './SurveyItem';

const SignUpSurveyItem: React.FC<{
  survey: SurveyType;
  className?: string;
}> = ({ survey, className }) => {
  const isLoggedIn = useIsLoggedIn();
  return (
    <Card
      component="a"
      href={isLoggedIn ? SCREENER_SURVEY.path : SIGN_UP.path}
      rel="noopener noreferrer"
      interactive
      className={clsx(classes.root, classes.unpaidCard, className)}
    >
      <Txt component="h4" className={classes.unpaidTitle}>
        {survey.id === 'static-prescreener' ? survey.title : 'Sign up to surveys'}
      </Txt>
      <Oinq className={classes.oinq} />
      <Button className={classes.signUpButton} buttonType="primary" color="secondary">
        <Txt>Start earning</Txt>
      </Button>
    </Card>
  );
};

export default SignUpSurveyItem;
