import React from 'react';

export default function useBoundingBox<T extends HTMLElement>(): [DOMRect | null, React.Ref<T>] {
  const ref = React.useRef<T>(null);
  const [bbox, setBbox] = React.useState<DOMRect | null>(null);

  const reloadBoundingBox = React.useCallback(
    () => setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : null),
    [],
  );

  // Dynamically placed elements sometimes take a while to be placed
  React.useEffect(() => {
    setTimeout(reloadBoundingBox, 500);
  }, [reloadBoundingBox]);

  React.useEffect(() => {
    reloadBoundingBox();
    window.addEventListener('resize', reloadBoundingBox);
    return () => window.removeEventListener('resize', reloadBoundingBox);
  }, [reloadBoundingBox]);

  return [bbox, ref];
}
