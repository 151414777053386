import clsx from 'clsx';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import React from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay/hooks';
import classes from './SurveyItem.module.scss';
import {
  SurveyItem_survey$data,
  SurveyItem_survey$key,
} from './__generated__/SurveyItem_survey.graphql';

import Image from 'components/atoms/Image/Image';
import useIsAdmin from 'hooks/useIsAdmin';
import Button from '../../../../src/components/atoms/Button/Button';
import LockedSurvey from '../../assets/lockedSurvey.svg';
import SensitiveIcon from '../../assets/sensitive.svg';
import PartCompletedIcon from '../../assets/PartCompletedIcon.svg';
import InfoIcon from '../../assets/survey-info.svg';
import Card from '../../atoms/Card/Card';
import Tag from '../../atoms/Tag/Tag';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import Txt from '../../atoms/Txt/Txt';
import SignUpSurveyItem from './SignUpSurveyItem';

export interface Props {
  surveyRef: SurveyItem_survey$key;
  className?: string;
  disableTooltip?: boolean;
}

export type SurveyType = Omit<Omit<SurveyItem_survey$data, ' $refType'>, ' $fragmentType'>;

export const surveyItemFragmentSpec = graphql`
  fragment SurveyItem_survey on Survey {
    id
    studyId
    supportId
    title
    reward {
      formatted
    }
    lockedInfo {
      title
      description
    }
    status
    statusDescription
    startedAt
    baseReward {
      formatted
    }
    duration
    durationDetails {
      max
      median
      min
      providerDuration
    }
    url
    imageUrl
    iconUrl
    tags
    fraudType
    adminData {
      surveyGuid
      rankScore
    }
  }
`;

function generateSurveyUrl(url: string | null): string | undefined {
  if (!url) {
    return undefined;
  }
  const urlObject = new URL(url);
  const params = new URLSearchParams(urlObject.search);
  if (window.location.host === 'alpha.qmee.com' || window.location.host === 'localhost:3000') {
    params.append('fromAlpha', 'true');
  }
  return `${urlObject.origin}${urlObject.pathname}?${params}`;
}

const SurveyItemHeader: React.FC<{
  survey: SurveyType;
  className?: string;
}> = ({ survey, className }) => {
  return (
    <header className={clsx(classes.categoryContainer, className)}>
      <Txt
        component="h5"
        className={clsx(classes.title, survey.tags.includes('broken') && classes.broken)}
        skipTranslation
      >
        {survey.title}
      </Txt>
      <div className={classes.tagContainer}>
        {survey.tags.map((tag) => (
          <Tag tag={tag} type="survey" key={tag} />
        ))}
      </div>
    </header>
  );
};

const SurveyIcon: React.FC<{
  survey: SurveyType;
  className?: string;
}> = ({ survey, className }) => {
  const isSensitive = survey.tags.includes('sensitive');
  const isLocked = survey.lockedInfo && !survey.tags.includes('broken');
  const isPartCompleted = survey.tags.includes('in_progress');

  return (
    <div
      className={clsx(
        classes.imageIconContainer,
        isPartCompleted && classes.partComplete,
        className,
      )}
    >
      <Image
        src={survey.imageUrl}
        alt={`for survey ${survey.title}`}
        width={84}
        height={84}
        className={clsx(classes.image, isSensitive && classes.sensitive)}
      />
      {isSensitive && (
        <div className={classes.sensitiveOverlay}>
          <SensitiveIcon fill="#FFF" height="28" width="28" />
        </div>
      )}
      {isLocked && (
        <div className={classes.lockedOverlay}>
          <LockedSurvey />
        </div>
      )}
      {isPartCompleted && (
        <div className={classes.partCompleted}>
          <PartCompletedIcon />
        </div>
      )}
    </div>
  );
};
const LockedSurveyItem: React.FC<{
  survey: SurveyType;
  className?: string;
}> = ({ survey, className }) => {
  return (
    <Card
      component="div"
      rel="noopener noreferrer"
      interactive
      className={clsx(classes.root, 'locked', className)}
    >
      <SurveyItemHeader survey={survey} />
      <div className={clsx(classes.informationContainer)}>
        {survey.reward?.formatted ? (
          <aside className={clsx(classes.moneyTimeContainer, classes.locked)}>
            <p className={classes.reward}>
              <span>{survey.reward?.formatted || ''}</span>
            </p>
            <p className={classes.time}>{`${survey.duration} min${
              survey.duration > 1 ? 's' : ''
            }`}</p>
          </aside>
        ) : (
          <SurveyIcon survey={survey} />
        )}
        <div className={clsx(classes.lockedInfo, survey.tags.includes('broken') && classes.broken)}>
          {survey.lockedInfo?.title && <Txt component="h4">{survey.lockedInfo?.title}</Txt>}
          {survey.lockedInfo?.description && (
            <Txt component="p">{survey.lockedInfo?.description}</Txt>
          )}
        </div>
      </div>
    </Card>
  );
};

export const PropSurveyItem: React.FC<{
  survey: SurveyType;
  className?: string;
  disableTooltip?: boolean | undefined;
}> = ({ survey, disableTooltip, className }) => {
  const isBroken = survey.tags.includes('broken');
  const isLocked = (survey.lockedInfo && !survey.tags.includes('broken')) || !survey.url;
  const isPartCompleted = survey.status === 'PENDING' && survey.url;
  const showUpdatedAmount = Boolean(
    survey.baseReward && survey.baseReward?.formatted !== survey.reward?.formatted,
  );
  const isPrescreener = survey.tags.includes('screener');
  const isAdmin = useIsAdmin();
  const hasDurationDetails =
    survey.durationDetails.min !== null ||
    survey.durationDetails.median !== null ||
    survey.durationDetails.max !== null;
  dayjs.extend(calendar);
  if (isPrescreener) {
    return <SignUpSurveyItem survey={survey} className={className} />;
  }
  if (isBroken) {
    return <LockedSurveyItem survey={survey} className={className} />;
  }
  return (
    <Card
      component={'a'}
      href={generateSurveyUrl(survey.url)}
      rel="noopener noreferrer"
      interactive={!isLocked}
      className={clsx(classes.root, classes.active, className)}
    >
      <SurveyItemHeader survey={survey} />
      {survey.adminData && isAdmin && (
        <div className={classes.adminInfo}>
          {survey.adminData.surveyGuid} : {survey.adminData.rankScore}
        </div>
      )}
      <div className={clsx(classes.informationContainer)}>
        <SurveyIcon survey={survey} />
        <aside className={classes.moneyTimeContainer}>
          <p className={classes.reward}>
            {showUpdatedAmount && (
              <s>
                {survey.baseReward?.formatted}
                <svg preserveAspectRatio="none" viewBox="0 0 32 32">
                  <line x1="0" y1="32" x2="32" y2="0" />
                </svg>
              </s>
            )}
            <span>{survey.reward?.formatted || ''}</span>
          </p>
          {/* eslint-disable prettier/prettier */}
          {!disableTooltip && hasDurationDetails ? (
            <Tooltip
              tip={
                <div className={classes.qmeeTimeContainer}>
                  <div className={classes.qmeeTimeTitle}>
                    <Txt component="h5">Qmee users take on average:</Txt>
                  </div>
                  <div className={classes.timeIllustration}>
                    <div className={classes.times}>
                      <div className={classes.timesMin}>
                        <Txt
                          component="span"
                          translateOptions={{
                            plural: '%1s; mins',
                            vars: [survey.durationDetails.min || 0],
                          }}
                        >
                          %1s; min
                        </Txt>
                      </div>
                      <div className={classes.timesMed}>
                        <Txt
                          component="span"
                          translateOptions={{
                            plural: '%1s; mins',
                            vars: [survey.durationDetails.median || 0],
                          }}
                        >
                          %1s; min
                        </Txt>
                      </div>
                      <div className={classes.timesMax}>
                        <Txt
                          component="span"
                          translateOptions={{
                            plural: '%1s; mins',
                            vars: [survey.durationDetails.max || 0],
                          }}
                        >
                          %1s; min
                        </Txt>
                      </div>
                    </div>
                    <div className={classes.notches}>
                      <div className={classes.notchCircleContainer}>
                        <div className={classes.notchesMin}>
                          <div className={classes.notch} />
                        </div>
                        <div className={classes.notchesMed}>
                          <div className={classes.notch} />
                        </div>
                        <div className={classes.notchesMax}>
                          <div className={classes.notch} />
                        </div>
                      </div>
                      <div className={classes.notchLine} />
                    </div>
                    <hr />
                    <div className={classes.providerTimeContainer}>
                      <Txt component="h5">Survey Provider Time:</Txt>
                      <Txt
                        component="span"
                        translateOptions={{
                          plural: '%1s; mins',
                          vars: [survey.durationDetails.providerDuration || 0],
                        }}
                      >
                        %1s; min
                      </Txt>
                    </div>
                  </div>
                </div>
              }
            >
              <p className={classes.time}>
                <InfoIcon />
                {survey.duration} min{survey.duration > 1 ? 's' : ''}
              </p>
            </Tooltip>
          ) : (
            <p className={classes.time}>{`${survey.duration} min${
              survey.duration > 1 ? 's' : ''
            }`}</p>
          )}
          {/* eslint-enable prettier/prettier */}
        </aside>
        {!isLocked && (
          <Button
            className={clsx(classes.button, isPartCompleted ? classes.continue : classes.standard)}
          >
            <Txt component="p">{isPartCompleted ? 'Continue' : 'Start'}</Txt>
          </Button>
        )}
      </div>
    </Card>
  );
};

const SurveyItem: React.FC<Props> = ({ surveyRef, className, disableTooltip }) => {
  const survey = useFragment(surveyItemFragmentSpec, surveyRef);
  return <PropSurveyItem survey={survey} disableTooltip={disableTooltip} className={className} />;
};

export default SurveyItem;
