import clsx from 'clsx';
import React from 'react';
import useBoundingBox from '../../../hooks/useBoundingBox';
import classes from './Tooltip.module.scss';

export interface Props {
  wrapperClassName?: string;
  tipClassName?: string;
  tip: JSX.Element | string;
  children: React.ReactNode;
}

const Tooltip: React.FC<Props> = ({ children, wrapperClassName, tipClassName, tip }) => {
  const [rootBox, rootRef] = useBoundingBox<HTMLDivElement>();
  const [tipBox, tipRef] = useBoundingBox<HTMLDivElement>();

  let layoutFix: string | null = null;
  if (
    rootBox &&
    tipBox &&
    rootBox.left + rootBox.width / 2 + tipBox.width / 2 > window.innerWidth
  ) {
    layoutFix = 'right';
  } else if (rootBox && tipBox && rootBox.left - rootBox.width / 2 + tipBox.width / 2 < 0) {
    layoutFix = 'left';
  }
  return (
    <div className={clsx(wrapperClassName, classes.root)} ref={rootRef}>
      {children}
      <div className={classes.arrow} />
      <div className={classes.tip} ref={tipRef} style={layoutFix ? { [layoutFix]: 0 } : undefined}>
        <div
          className={tipClassName}
          style={{ flexDirection: 'column', right: layoutFix ? '0' : 'inherit' }}
        >
          {tip}
        </div>
      </div>
    </div>
  );
};
export default Tooltip;
