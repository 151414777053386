/**
 * @generated SignedSource<<d83451cd42cd8aa9b027d38d9214eb4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SurveyFraudType = "GENERAL" | "OPEN_ENDED" | "SPEEDING" | "TRAP_QUESTION";
export type SurveyStatus = "AVAILABLE" | "FAIL" | "PENDING" | "SUCCESS";
import { FragmentRefs } from "relay-runtime";
export type SurveyItem_survey$data = {
  readonly id: string;
  readonly studyId: string | null;
  readonly supportId: string | null;
  readonly title: string;
  readonly reward: {
    readonly formatted: string;
  } | null;
  readonly lockedInfo: {
    readonly title: string | null;
    readonly description: string | null;
  } | null;
  readonly status: SurveyStatus;
  readonly statusDescription: string;
  readonly startedAt: string | null;
  readonly baseReward: {
    readonly formatted: string;
  } | null;
  readonly duration: number;
  readonly durationDetails: {
    readonly max: number | null;
    readonly median: number | null;
    readonly min: number | null;
    readonly providerDuration: number;
  };
  readonly url: string | null;
  readonly imageUrl: string;
  readonly iconUrl: string;
  readonly tags: ReadonlyArray<string>;
  readonly fraudType: SurveyFraudType | null;
  readonly adminData: {
    readonly surveyGuid: string;
    readonly rankScore: number;
  } | null;
  readonly " $fragmentType": "SurveyItem_survey";
};
export type SurveyItem_survey$key = {
  readonly " $data"?: SurveyItem_survey$data;
  readonly " $fragmentSpreads": FragmentRefs<"SurveyItem_survey">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "formatted",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SurveyItem_survey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MoneyAmount",
      "kind": "LinkedField",
      "name": "reward",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LockedSurveyInfo",
      "kind": "LinkedField",
      "name": "lockedInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MoneyAmount",
      "kind": "LinkedField",
      "name": "baseReward",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DurationDetails",
      "kind": "LinkedField",
      "name": "durationDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "max",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "median",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "min",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "providerDuration",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fraudType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminSurveyData",
      "kind": "LinkedField",
      "name": "adminData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "surveyGuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rankScore",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Survey",
  "abstractKey": null
};
})();

(node as any).hash = "998acf98f46363efee3ba188065397ac";

export default node;
